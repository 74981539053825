
/deep/.el-tabs__content {
  /*box-shadow:0px 0px 16px 1px rgba(62,58,234,0.1);*/
  /*border-radius:0px 10px 20px 10px;*/
}

.dataCenterDetail {
  height: 100%;
  .education-content {
    height: 100%;
  }
}

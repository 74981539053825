
.theory-content {
    padding: 30px;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;

    .exerciseDetail-main {
        height: 1%;
        flex: 1;
        margin-top: 20px;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }

    ::v-deep .el-breadcrumb__item {

        &:last-child {

            .el-breadcrumb__inner {
                color: #13131B;
                font-weight: bold;
            }
        }

        .el-breadcrumb__inner {
            color: #999;
        }
    }

    .paper-table {
        display: flex;
        margin: 24px 0;
        height: 100px;

        .paper-total {
            display: flex;
            flex-direction: column;
            border: 1px solid #EAEAEA;
            height: 100%;

            .total {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                color: #333333;
                width: 184px;
            }
        }

        .answer-case {
            display: flex;
            flex-direction: column;
            border: 1px solid #EAEAEA;
            border-left: none;
            height: 100%;
            width: 184px;
            justify-content: center;
            align-items: center;

            .case-title {
                font-size: 16px;
                color: #333333;
            }

            .case-score {
                font-size: 30px;
                margin-top: 10px;

                .score-unit {
                    font-size: 16px;
                }
            }
        }

        .score-item {
            color: #1122D8;
        }

        .topic-type {
            display: flex;
            flex-direction: column;
            border: 1px solid #EAEAEA;
            border-left: none;
            height: 100%;
            flex: 1;


            .type-item {
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #EAEAEA;
                box-sizing: border-box;
                font-size: 18px;
                font-weight: 400;
                height: 50%;

                .text-gray {
                    font-weight: 400;
                    color: #333333;
                    margin-left: 5px;
                }
            }
        }
    }

    .paper-name {
        margin-top: 38px;
        text-align: center;
        font-size: 18px;
        color: #333333;
    }
}

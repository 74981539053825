
.shopDecoration-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
    .look-detail {
        color: #0200D8;
        &:hover {
            color: #5864e4;
        }
    }
    ::v-deep.el-dialog__header{
        background: #0200D8;
        span{
            color: #FEFEFE;
            font-weight:400;
            font-size: 16px;
            /*line-height: 30px;*/
        }
    }
    ::v-deep.el-dialog__close{
        color: #FFFFFF;
        font-size: 14px;
    }
    ::v-deep.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    ::v-deep.cell{
        line-height: 1;
    }
    ::v-deep.answer{
        display: inline-block;
        line-height: 1;
    }
    ::v-deep.answerImg{
        display: inline-block;
        max-height: 43px;
        border: none;
    }
    ::v-deep.el-carousel__arrow{
        width: 60px;
        height: 60px;
        font-size: 25px;
        background: #000000;
        opacity: 0.5;
    }
    ::v-deep.el-carousel__indicators--outside{
    }
    ::v-deep.el-carousel__indicator .el-carousel__button{
        width: 20px;
        height: 8px;
        opacity: 1;
        background:#ffffff;
        border-radius:4px;
        border:1px solid rgba(255,255,255,1);
    }
    ::v-deep.is-active .el-carousel__button{
        background: #0200D8;
        border: #0200D8 1px solid;
    }
    ::v-deep.el-carousel__item{
        display: flex;
        align-items: center;
        justify-content: center;
        /*height: 556px;*/
    }
    ::v-deep.el-carousel__container{
        height: 556px;
    }
    .shopDecoration {
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .detail-cover{
            width: 100%;
            height: 100%;
            position: fixed;
            background: rgba(0,0,0,0.3);
            left: 0;
            top: 0;
            z-index: 99;
            .cover-box{
                width: 70%;
                margin: 10% auto;
                .cover-item{
                    /*height: 566px;*/
                    /*margin: 0 auto;*/
                }
            }
        }
    }
    .el-breadcrumb {
        line-height: 40px;
    }
    .good-cover-wrapper {
        text-align: center;
        .good-cover {
            display: flex;
            align-items: center;
            justify-content: center;
            .item {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .item + .item {
                margin-left: 10px;
            }
            .banner {
                /*width: 80%;*/
                width: 182.857142px;
                height: 0;
                /*padding-bottom: 26.25%;*/
                padding-bottom: 60px;
                position: relative;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .dianzhao {
                width: 960px;
                height: 0;
                padding-bottom: 60px;
                position: relative;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }


.null-answer {
    color: #7D7D7D;
}

.paperDetail-content {
    padding: 16px 0;

    .questionType-item {
        margin-top: 20px;

        .questionType-title {
            display: flex;
            align-items: center;
            margin-left: 30px;

            .title-index {

            }

            .title {
                font-size: 16px;
                color: #343441;
                font-weight: 400;
            }

            .subtitle {
                font-size: 14px;
                color: #343441;
                margin-left: 10px;
            }
        }

        .question-item {
            font-size: 14px;
            color: #343441;

            .question-title {
                margin-top: 20px;
                background: #F2F3FA;
                line-height: 50px;
                padding: 0px 40px;

                ::v-deep p {
                    display: inline;
                }
            }

            .answer-box {
                .question-answer {
                    padding: 0 40px;
                    display: flex;
                    flex-direction: column;

                    .answer-item {
                        margin-top: 20px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333;

                        &.el-radio {
                            white-space: normal;
                        }

                        ::v-deep .el-radio__input {
                            white-space: normal;

                            &.is-checked {
                                & + .el-radio__label {
                                    color: #1122D8;
                                }

                                .el-radio__inner {
                                    border-color: #1122D8;
                                    background: #fff;

                                    &::after {
                                        background-color: #1122D8;
                                    }
                                }
                            }

                            .el-radio__inner {
                                &:hover {
                                    border-color: #1122D8;
                                }
                            }
                        }

                        &.el-checkbox {
                            white-space: normal;
                        }

                        ::v-deep .el-checkbox__input {
                            white-space: normal;

                            &.is-checked {
                                & + .el-checkbox__label {
                                    color: #1122D8;
                                }

                                .el-checkbox__inner {
                                    background-color: #1122D8;
                                    border-color: #1122D8;
                                }
                            }

                            &.is-indeterminate {
                                .el-checkbox__inner {
                                    background-color: #1122D8;
                                    border-color: #1122D8;
                                }
                            }

                            .el-checkbox__inner {
                                &:hover {
                                    border-color: #1122D8;
                                }
                            }
                        }
                    }

                    .answer-content {
                        display: flex;
                        flex-wrap: wrap;

                        .answer-item {
                            margin-left: 30px;

                            &:first-of-type {
                                margin-left: 0;
                            }
                        }

                        //.answer-after{
                        //  &:after{
                        //    content: '、';
                        //  }
                        //}
                        //.answer-after:last-child{
                        //  &:after{
                        //    content: '';
                        //  }
                        //}
                    }
                }

                .question-analysis-error {
                    background: #FCF1ED;
                }

                .question-analysis-correct {
                    background: #F3FFF8;
                }

                .question-not-check {
                    background: #F2F3FA;
                }

                .question-analysis {
                    position: relative;
                    margin: 50px 30px 0;
                    padding: 13px 54px;
                    display: flex;
                    flex-wrap: wrap;

                    span {
                        margin-right: 60px;
                        line-height: 1;
                        font-size: 14px;
                        font-weight: 400;

                        .strong-error {
                            color: #FF0000;
                        }

                        .strong-correct {
                            color: #3BB400;
                        }

                        .strong-after {
                            &:after {
                                content: '、';
                            }

                            &:last-child {
                                &:after {
                                    content: '';
                                }
                            }
                        }

                        strong {
                            font-weight: 400;
                        }
                    }

                    .answer-judge {
                        position: absolute;
                        left: 40px;
                        top: -30px;
                        line-height: 1;
                        padding: 8px 14px;
                        border-radius: 4px 4px 0px 0px;
                    }

                    .answer-judge-error {
                        background: #FCF1ED;
                        color: #FF0000;
                    }

                    .answer-judge-correct {
                        background: #F3FFF8;
                        color: #3BB400;
                    }

                    .answer-not-check {
                        background: #F2F3FA;
                    }

                    .isAnalysis {
                        display: flex;
                        align-items: center;

                        span {
                            cursor: pointer;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }
}

.isAnalysis-content {
    padding: 18px 54px;
    border: 1px solid #EEEEEE;
    font-size: 14px;
    margin: 0 30px;

    .title {
        color: #FF0000;
    }

    .analysis-span {
        ::v-deep p {
            display: inline;
            font-size: 14px;
            color: #333;
        }
    }
}
